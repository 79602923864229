exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-node-page-path-alias-js": () => import("./../../../src/pages/{nodePage.path__alias}.js" /* webpackChunkName: "component---src-pages-node-page-path-alias-js" */),
  "component---src-pages-node-programme-path-alias-js": () => import("./../../../src/pages/{nodeProgramme.path__alias}.js" /* webpackChunkName: "component---src-pages-node-programme-path-alias-js" */),
  "component---src-pages-node-story-path-alias-js": () => import("./../../../src/pages/{nodeStory.path__alias}.js" /* webpackChunkName: "component---src-pages-node-story-path-alias-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-taxonomy-term-tags-js": () => import("./../../../src/templates/taxonomyTermTags.js" /* webpackChunkName: "component---src-templates-taxonomy-term-tags-js" */)
}

